import { instance } from '@/utils/http.js'

// 纠错
export const errorCorrect = function (data) {
  return instance({
    url: '/api/v1/error/report',
    method: 'post',
    data
  })
}


// 收藏
export const collect = function (data) {
  return instance({
    url: '/api/v1/my/favorite',
    method: 'post',
    data
  })
}

// 获取标签列表
export const getTag = function (params) {
  return instance({
    url: '/api/v1/my/favorite_type',
    method: 'get',
    // params
  })
}