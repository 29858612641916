import { instance } from '@/utils/http.js'
import Vue from 'vue'

// 获取学生科目
export const getStudentSubject = function () {
  return instance({
    url: '/api/v1/cp/subject_list',
    method: 'get',
  })
}

//获取所有科目
// export const getAllSubject = function (params) {
//   return instance({
//     url: '/api/v1/cp/subject_list',
//     method: 'get',
//     params
//   })
// }