<template>

  <el-dialog title=""
             :visible.sync="dialogVisible"
             width="30%"
             :before-close="handleClose">
    <el-radio v-model="radio"
              label="1">打印题目答案跟解析</el-radio>
    <el-radio v-model="radio"
              label="0">不打印题目答案跟解析</el-radio>
    <span slot="footer"
          class="dialog-footer">
      <el-button @click="dialogVisible = false">取 消</el-button>
      <el-button type="primary"
                 @click="confirm">确 定</el-button>
    </span>
  </el-dialog>

</template>

<script>
export default {
  data () {
    return {
      dialogVisible: false,
      radio: '1'
    };
  },
  methods: {
    confirm () {
      this.$emit('confirm', this.radio); this.dialogVisible = false;
    },
    handleClose () {
      this.dialogVisible = false;
    }
  }
};
</script>

<style scoped>
::v-deep .el-dialog__body {
  display: flex;
  justify-content: center;
}
</style>