
import { instance } from '@/utils/http.js'

// 错题本列表
export const getErrorList = function (params) {
  return instance({
    url: '/api/v1/my/wrong_topic',
    method: 'get',
    params
  })
}
//收藏列表
export const getCollectList = function (params) {
  return instance({
    url: '/api/v1/problems/favorite_lst',
    method: 'get',
    params
  })
}


// 收藏删除
export const delCollect = function (data) {
  return instance({
    url: '/api/v1/problems/del',
    method: 'post',
    data
  })
}

// 删除错题
export const delError = function (data) {
  return instance({
    url: '/api/v1/my/wrong_topic_del',
    method: 'post',
    data
  })
}

// // 错题本修改
export const editError = function (data) {
  return instance({
    url: '/api/v1/problems/edit',
    method: 'post',
    data
  })
}

// 收藏题目详情
export const favoriteInfo = function (params) {
  return instance({
    url: '/api/v1/problems/show',
    method: 'get',
    params
  })
}


// 根据科目获取收藏题型
export const getCollectType = function (params) {
  return instance({
    url: '/api/v1/problems/favorite_question_type_lst',
    method: 'get',
    params
  })
}


// 根据科目获取错题题型
export const getWrongType = function (params) {
  return instance({
    url: '/api/v1/my/wrong_topic_question_type',
    method: 'get',
    params
  })
}



// 错误题目详情
export const errorInfo = function (params) {
  return instance({
    url: '/api/v1/my/wrong_topic_detail',
    method: 'get',
    params
  })
}

// 根据id获取知识点相关信息
export const getKnowledge = function (params) {
  return instance({
    url: '/api/v1/my/knowledge',
    method: 'get',
    params
  })
}
// 根据id获取考点相关信息
export const getCapability = function (params) {
  return instance({
    url: '/api/v1/my/capability',
    method: 'get',
    params
  })
}
// 根据id获取能力素养相关信息
export const getPoint = function (params) {
  return instance({
    url: '/api/v1/my/check_point',
    method: 'get',
    params
  })
}
// 讲义
export const getLecture = function (params) {
  return instance({
    url: '/api/v1/my/lecture',
    method: 'get',
    params
  })
}

//错题科目列表
export const getWrongSubject = function () {
  return instance({
    url: '/api/v1/my/wrong_question_subject',
    method: 'get'
  })
}
//收藏科目列表
export const getCollectSubject = function () {
  return instance({
    url: '/api/v1/problems/favorite_subject',
    method: 'get'
  })
}


// 修改错题评论
export const editComment = function (data) {
  return instance({
    url: '/api/v1/my/comment',
    method: 'post',
    data
  })
}

// 修改收藏评论
export const editCollectComment = function (data) {
  return instance({
    url: '/api/v1/problems/edit',
    method: 'post',
    data
  })
}

//导出错题
export const exportWrongPrint = function (params) {
  return instance({
    url: '/api/v1/my/wrong_print',
    method: 'get',
    responseType: "blob",
    params
  })
}




