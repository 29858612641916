<template>
  <div>
    <el-dialog :visible.sync="dialogVisible"
               :before-close="handleClose"
               title="讲义查询">
      <el-input placeholder="请输入讲义名称"
                v-model="keyword">

      </el-input>

      <span slot="footer"
            class="dialog-footer">
        <el-button @click="search">确定</el-button>
        <el-button @click="handleClose">取消</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data () {
    return {
      dialogVisible: false,
      keyword: ''
    }
  },
  methods: {
    search () {
      this.$emit('search', this.keyword)
      this.handleClose()
    },
    handleClose () {
      this.dialogVisible = false
    }
  }
}
</script>

<style>
</style>