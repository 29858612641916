<template>
  <div class="subject_search">
    <div class="subject">
      <div v-for="(item,index) in list"
           :class="{'active_subject':subjectId == item.subject_id}"
           @click="checkSubject(item,index)"
           :key="item.subject_id">
        {{item.subject_name}}
      </div>
    </div>
    <div v-show="showSearch"
         style="width:50rem;font-size:26rem;color:#333;cursor:pointer">
      <i class="el-icon-search"
         slot="suffix"
         @click="showSearchDialog">
      </i>
    </div>
    <searchDialog ref="searchRef"
                  @search="search" />
  </div>
</template>

<script>
import { getStudentSubject } from '@/api/subject.js'
import { mapState, mapMutations } from 'vuex'
import searchDialog from './searchDialog.vue'

export default {
  data () {
    return {
      // subjectList: [],
      subjectId: '',
      index: 0
    }
  },
  // prop: ['initSubject'],
  props: {
    showSearch: {
      type: Boolean,
      default: false
    }
  },
  mounted () {
    // this.$nextTick(() => {
    //     this.getSubjectList()
    // })

    // this.$nextTick(() => this.getSubjectList())
  },
  watch: {
    // initSubject () {
    //   console.log(1)
    //   this.$nextTick(() => this.getSubjectList())
    // }
  },
  computed: {
    ...mapState(['subjectList']),
    list () {
      return this.subjectList.filter(item => item.subject_id != 13)
    }
  },
  components: {
    searchDialog
  },
  methods: {
    ...mapMutations(['SET_SUBJECT']),
    async getSubjectList () {
      if (this.subjectList.length == 0) {
        const { data } = await getStudentSubject()
        this.SET_SUBJECT(data.list)
      }
      this.subjectId = this.subjectList[0].subject_id
      this.$emit('initList', this.subjectId)
    },
    checkSubject (item, index) {
      this.index = index
      this.subjectId = item.subject_id
      this.$emit('initList', this.subjectId, this.index)
    },
    search (keyword) {
      this.$emit('initList', this.subjectId, this.index, keyword)
    },
    showSearchDialog () {
      this.$refs.searchRef.dialogVisible = true
    },
    async refresh () {
      if (!this.subjectId) {
        await this.getSubjectList()
      }
      else {
        this.$emit('initList', this.subjectId, this.index)
      }
    },
  }
}
</script>

<style lang="scss" scoped>
.subject_search {
  display: flex;
  height: 75rem;
  justify-content: center;
  background: #ffffff;
  align-items: center;
  // border-bottom: 2rem #e5e5e5 solid;
  box-shadow: inset 0px -1px 0px 0px #cedaee;
  .subject {
    flex: 1;
    display: flex;
    justify-content: center;

    div {
      font-size: 30rem;
      font-weight: 500;
      color: #333333;
      padding: 0 14rem;
      margin: 0 25rem;
      cursor: pointer;
      display: flex;
      justify-content: center;
      align-items: center;
      height: 75rem;
    }
  }
  .active_subject {
    border-bottom: 4rem solid #1890ff;
    color: #2196f3 !important;
    z-index: 1999;
  }
}
</style>