<template>
  <div class="test_group_warp">
    <!-- <div class="left">
      <LeftController @initList="initList" />
    </div> -->
    <div class="top">
      <div class="select_wrap">
        <!-- <div> -->
        <div class="tab">
          <div :class="typeIndex==1?'active':''"
               @click="changeType(1)">我的错题</div>
          <div :class="typeIndex==2?'active':''"
               @click="changeType(2)">我的收藏</div>
        </div>
        <!-- </div> -->
        <div class="keyword_wrap">
          <el-button style="margin-right: 10px;border-radius: 16rem;"
                     @click="openDialog">{{typeIndex==1?'导出错题':'导出收藏'}}</el-button>
          <div class="type_id"
               v-show="typeIndex==2">
            <el-select style="width:100%"
                       v-model="question_favorite_type_id"
                       placeholder="请选择标签"
                       clearable
                       @change="refresh(id)">
              <el-option v-for="item in tagList"
                         :key="item.question_favorite_type_id"
                         :label="item.type_name"
                         :value="item.question_favorite_type_id">
              </el-option>
            </el-select>
          </div>
          <div class="type_id">
            <el-date-picker v-model="dateValue"
                            type="datetimerange"
                            @change="changeDate"
                            value-format="yyyy-MM-dd HH:mm:ss"
                            range-separator="至"
                            start-placeholder="开始日期"
                            end-placeholder="结束日期">
            </el-date-picker>
          </div>
          <div class="type_id">
            <el-select style="width:100%"
                       v-model="question_type_id"
                       placeholder="请选择题型"
                       clearable
                       @change="refresh(id)">
              <el-option v-for="item in typeList"
                         :key="item.question_type_id"
                         :label="item.question_type_name"
                         :value="item.question_type_id">
              </el-option>
            </el-select>
          </div>
          <div class="keyword">
            <el-input placeholder="请输入内容"
                      v-model="keywords">
              <!-- suffix-icon="el-icon-search" -->
              <i slot="append"
                 class="el-icon-search"
                 @click='refresh(id)'></i>
            </el-input>
          </div>
        </div>
      </div>
      <!-- 科目 -->
      <SubjectImg @initList="changeSubject"
                  ref="subjectRef" />
    </div>
    <div class="bottom">
      <div class="list"
           id="errorBookList"
           v-show="typeIndex==1">
           <el-checkbox v-model="isAllChecked" @change="handleSelectAllChange">全选</el-checkbox>

        <div class="item"
             v-for="(row,index) in errorList"
             :key="index">
          <div>
            <el-checkbox v-model="row.isCheck"
                         @change="handleCheckChange(row.isCheck,row.question_id)"></el-checkbox>
          </div>
          <div style="width: 100%;border: 1px solid #eee;overflow: hidden;border-radius: 8rem;">
            <div class="title errTitle">
              <div class="btn_group">
                <div class="btn"
                     @click.stop="delRowError(row)">移除错题</div>
                <div>题型：{{row.question_type_name}}</div>
              </div>

              <div class="time"> <img src="@/assets/self_correct/收藏.png"
                     v-if="!row.has_favorite"
                     @click="collect(row)"
                     alt="">
                <img src="@/assets/self_correct/已收藏.png"
                     v-else
                     @click="cancelCollect(row)"
                     alt="">答题时间：{{row.paper_time}}
              </div>

            </div>

            <div class="content"
                 @click="edit(row)">

              <!-- <div>题目标题：{{row.question_category_name}}</div> -->
              <div class="topicTitle">题目标题：<span v-html="row.question_content"></span></div>
              <div class="note">笔记： {{row.comment_to_user?row.comment_to_user:'无'}}</div>
            </div>
          </div>
        </div>
      </div>
      <div class="list"
           id="collectBookList"
           v-show="typeIndex==2">
        <div class="item"
             v-for="(row,index) in collectList"
             :key="index">
          <div>
            <el-checkbox v-model="row.isCheck"
                         @change="handleCheckChange(row.isCheck,row.question_id)"></el-checkbox>
          </div>
          <div style="width: 100%;border: 1px solid #eee;overflow: hidden;border-radius: 8rem;">
            <div class="collect_title title">

              <!-- <div class="btn">
                <span >查看内容</span>
              </div> -->
              <div>
                <div class="btn_del">
                  <span @click.stop="delRow(row)">移除收藏</span>
                </div>

                <div>题型：{{row.question_type_name}}</div>
              </div>
              <div class="time">答题时间：{{row.time_input}}</div>
            </div>

            <div class="content"
                 @click="edit(row)">
              <!-- <div>题型：{{row.question_type_name}}</div> -->
              <!-- <div>题目标题：{{row.question_category_name}}</div> -->
              <div class="topicTitle">题目标题：<div v-html="row.question_content"></div>
              </div>
              <div class="note">笔记： {{row.favorite_tags?row.favorite_tags:'无'}}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="pagination">
      <pagination ref="pagination"
                  :get-data="changePage"
                  :now-page.sync="page"
                  :now-size.sync="size"
                  :total="total" />
    </div>
    <Form ref="FormRef" />
    <Tag ref="TagRef" />
    <VideoForm ref="VideoFormRef" />

    <CheckDialog ref="CheckDialogRef"
                 @confirm="exportError" />
    <div class="autoModal"
         v-if="autoModalFlag">
      <div class="openButton">

        <el-button type="warning"
                   @click="toBuy"
                   class="btntext"><span class="text">开通VIP享受全部功能</span></el-button>
      </div>
    </div>
  </div>
</template>

<script>
import SubjectImg from '@/views/components/subject_card/subjectImage.vue'
// import TopCard from '@/views/components/top_card/index.vue'
import SubjectCard from "@/views/components/subject_card/index.vue"
import { getTag } from '@/api/error.js'
import { getErrorList, delError, delCollect, getCollectList, getCollectType, getWrongType, exportWrongPrint } from '@/api/errorbasis.js'
import Form from './components/form.vue'
import VideoForm from './components/video.vue'
import Tag from './components/tag.vue'
import CheckDialog from './components/checkDialog.vue'
export default {
  data () {
    return {
      // tableData: [],
      keywords: '',
      page: 1,
      id: 0,//科目
      question_type_id: '',//题型
      question_favorite_type_id: [],
      typeIndex: 1,//1错题，2收藏
      collectList: [],
      errorList: [],
      typeList: [],
      tagList: [],
      total: 0,
      size: 20,
      autoModalFlag: false,
      start_time: '',//开始时间
      end_time: '',//结束时间
      dateValue: '',//日期选择器值
      question_ids: [],
      detail: null,
      isAllChecked: false // 全选状态
    }
  },
  components: {
    // LeftController, 
    Form, VideoForm, SubjectCard, SubjectImg, CheckDialog, Tag
    // TopCard,
  },
  mounted () {
    this.$refs.subjectRef.initData(this.typeIndex)
    this.getTagList()
    let userInfo = JSON.parse(window.localStorage.getItem('userInfo'));
    if (userInfo.user_type_id == 14 && !userInfo.vip_end_time) {
      this.autoModalFlag = true
    }
  },
  methods: {
    handleCheckChange (isCheck, id) {

      if (isCheck) {
        this.question_ids.push(id)
      } else {
        this.question_ids = this.question_ids.filter(item => item !== id)
      }
    },
    handleSelectAllChange(isAllChecked) {
      // 处理全选复选框变化
      this.errorList.forEach(row => {
        row.isCheck = isAllChecked;
        this.question_ids = isAllChecked ? this.errorList.map(item => item.question_id) : []
      });
    },
    openDialog () {
      this.$refs.CheckDialogRef.dialogVisible = true
    },
    async exportError (radio) {

      if (this.question_ids.length === 0) {
        this.$message.warning('请选择要导出的错题')
        return
      }
      const data = await exportWrongPrint({
        question_ids: this.question_ids,
        detail: radio

      })
      const a = document.createElement("a")
      a.href = window.URL.createObjectURL(data)
      a.download = `错题本.pdf`
      a.click()
    },
    toBuy () {
      this.$router.push('/buyVip')
    },
    async getWrongTypeList () {
      const { data } = await getWrongType({
        subject_id: this.id
      })
      this.typeList = data.list
    },

    async getCollectTypeList () {
      const { data } = await getCollectType({
        subject_id: this.id
      })
      this.typeList = data.list
    },
    async getTagList () {
      const { data } = await getTag()
      this.tagList = data.list

    },
    changeType (index) {
      this.typeIndex = index
      this.page = 1
      // console.log(this.typeIndex, 'index')
      this.$refs.subjectRef.initData(this.typeIndex, this.id)
      // this.initList(this.page, this.size, this.id)
    },
    changePage (page, limit) {
      if (page || limit) {
        this.page = page
        this.size = limit
      }
      this.initList(this.id)
    },
    changeSubject (id) {
      this.id = id
      this.page = 1
      this.question_type_id = ''
      this.initList(id)
      if (this.typeIndex == 1) {
        this.getWrongTypeList()
      } else if (this.typeIndex == 2) {
        this.getCollectTypeList()
      }
    },
    async initList (id) {
      if (!id && !this.id) {
        return
      }
      if (id && this.id != id) {
        this.id = id
        this.page = 1
        // this.tableData = []
      }

      let params = {
        subject_id: this.id,
        keyword: this.keywords,
        question_type_id: Number(this.question_type_id),
        question_favorite_type_id: this.typeIndex == 2 && this.question_favorite_type_id ? this.question_favorite_type_id : '',
        page: this.page,
        limit: this.size,
        start_time: this.start_time,
        end_time: this.end_time,
        question_id: this.keywords
      }
      this.question_ids = []
      if (this.typeIndex == 2) {
        this.collectList = []
        const { data } = await getCollectList(params)
        this.collectList = data.list
        this.total = data.count
        let collectBookList = document.getElementById('collectBookList')
        collectBookList.scrollTop = 0
      } else if (this.typeIndex == 1) {
        this.errorList = []
        const { data } = await getErrorList(params)
        this.errorList = data.list
        this.total = data.count
        let errorBookList = document.getElementById('errorBookList')
        errorBookList.scrollTop = 0
      }
    },
    delRow (row) {
      this.$confirm("确定删除？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(async () => {
        let params = {
          id: row.question_favorite_id
        }
        await delCollect(params)
        this.$message.success('成功')
        this.page = 1
        this.initList(this.id)
      })
    },
    delRowError (row) {
      this.$confirm("确定已掌握该题目，将其移出错题本？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(async () => {
        let params = {
          user_paper_detail_id: row.user_paper_detail_id
        }
        await delError(params)
        this.$message.success('成功')
        this.page = 1
        this.initList(this.id)
      })
    },
    changeDate (val) {
      if (val && val.length > 0) {
        this.start_time = this.dateValue[0] ? this.dateValue[0] : ''
        this.end_time = this.dateValue[1] ? this.dateValue[1] : ''
      } else {
        this.start_time = ''
        this.end_time = ''
      }

      this.refresh()

    },
    collect (row) {
      let form = {
        question_id: row.question_id,
        tags: ''
      }
      this.$refs.TagRef.dialogVisible = true
      this.$refs.TagRef.form = form
    },
    async cancelCollect (row) {
      this.$confirm("确定取消收藏这道题目？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(async () => {
        await delCollect({
          id: row.question_favorite_id
        })
        this.$message.success('取消收藏成功')
        row.has_favorite = 0
      }).catch(e => e)
    },
    refresh () {
      this.page = 1
      this.initList(this.id)
    },
    edit (row) {
      if (this.typeIndex == 1) {
        this.$router.push('/topic/infoC?user_paper_detail_id=' + row.user_paper_detail_id)
      } else {
        this.$router.push('/topic/infoC?question_id=' + row.question_id)
      }
      // let form = {
      //   id: row.question_favorite_id,
      //   tags: row.favorite_tags
      // }
      // this.$refs.FormRef.form = form
      // this.$refs.FormRef.dialogVisible = true
    },
    // lookVideo (row) {
    //   if (!row.video_list.length) {
    //     this.$message.warning('该错题没有视频讲解')
    //     return
    //   }
    //   this.$refs.VideoFormRef.tableData = row.video_list
    //   this.$refs.VideoFormRef.dialogVisible = true
    // }
  },
  // 跳转到错题详情，页面缓存，其他不缓存
  beforeRouteLeave (to, from, next) {
    if (to.path != '/topic/infoC') {
      from.meta.keep = false
    }
    next();
  },
  beforeRouteEnter (to, from, next) {
    to.meta.keep = true
    next()
  },
}
</script>

<style lang="scss" scoped>
::v-deep .el-checkbox {
  line-height: 45rem;
  color: var(--light-333333, #333);
  margin-right: 10rem;
  font-size: 20rem;
  .el-checkbox__label {
    font-size: 20rem;
  }
  .el-checkbox__inner {
    width: 20rem;
    height: 20rem;
    border: 2px solid #5c5b5b;
  }
  .el-checkbox__inner::after {
    height: 11px;
    width: 7px;
  }
}
.autoModal {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  opacity: 1.5;
  background: linear-gradient(
    to bottom,
    rgba(255, 255, 255, 0),
    rgba(255, 255, 255, 1)
  );
  .btntext {
    position: fixed;
    left: 800rem;
    top: 700rem;
    .text {
      color: var(--light-333333, #333);
      text-align: center;

      font-size: 24rem;
      font-style: normal;
      font-weight: 600;
      line-height: 36rem; /* 150% */
    }
  }
  .openButton {
    ::v-deep .el-button {
      display: flex;
      width: 360rem;
      height: 64rem;
      justify-content: center;
      align-items: center;
      gap: 10rem;
      flex-shrink: 0;
      border-radius: 8rem;
      background: linear-gradient(100deg, #fdecc9 0%, #e9c383 100%);
      box-shadow: 0rem 8rem 16rem 0rem rgba(0, 0, 0, 0.1);
    }
  }
}
.select_wrap {
  display: flex;
  flex-wrap: wrap;
  align-items: baseline;
  justify-content: space-between;
}
.top {
  overflow: hidden;
  margin: 0 32rem;
  position: relative;
  .keyword_wrap {
    display: flex;
    // position: absolute;
    right: 10rem;
    top: 32rem;
    align-items: baseline;
  }
  .keyword {
    display: inline-block;
    border: solid 1px #cccccc;
    background: #fff;
    overflow: hidden;
    border-radius: 30rem;
    ::v-deep .el-input--medium .el-input__inner {
      height: 44rem;
      line-height: 44rem;
      // border-radius: 30rem;
      border: 0;
    }
    ::v-deep .el-input-group__append,
    .el-input-group__prepend {
      background-color: #fff;
      border: 0;
      padding: 0 20rem;
      width: 1px;
      white-space: nowrap;
      cursor: pointer;
    }
    .el-input--medium {
      font-size: 20rem;
    }
  }
  .type_id {
    display: inline-block;
    margin-right: 10rem;
    border: solid 1px #cccccc;
    background: #fff;
    overflow: hidden;
    border-radius: 30rem;
    ::v-deep .el-input--medium .el-input__inner {
      height: 44rem;
      line-height: 44rem;
      // border-radius: 30rem;
      font-size: 20rem;
      border: 0;
    }
    ::v-deep .el-range-editor--medium.el-input__inner {
      height: 44rem;
      line-height: 44rem;
      border: 0;
      width: 100%;
      span {
        font-size: 20rem;
        height: 44rem;
        line-height: 44rem;
      }
    }
    ::v-deep .el-range-editor--medium .el-range-input {
      font-size: 20rem;
    }
    ::v-deep .el-input__icon {
      height: 44rem;
      line-height: 44rem;
    }
  }
}
.tab {
  padding: 45rem 0rem 10rem;
  div {
    margin-right: 25rem;
    display: inline-block;
    width: 132rem;
    line-height: 44rem;
    text-align: center;
    font-size: 24rem;
    color: #999;
    cursor: pointer;
  }
  .active {
    font-size: 30rem;
    color: #333;
    position: relative;
    &::after {
      content: "";
      width: 132rem;
      height: 6rem;
      position: absolute;
      display: block;
      bottom: -10rem;
      left: 0;
      border-radius: 20rem;
      background: #2196f3;
    }
  }
}
.list {
  background: #fff;
  border-radius: 10rem;
  margin: 8rem 28rem 28rem;
  height: 80vh;
  padding: 10rem;
  overflow: auto;
}
.item {
  border-radius: 8rem 8rem 0 0;
  overflow: hidden;
  // box-shadow: 0px 3px 6px 1px rgba(0, 0, 0, 0.16);
  // margin: 5rem 5rem 25rem;
  display: flex;
  align-items: center;
  margin: 5rem 5rem 0rem;
  // border: 1px solid #eee;
  border-bottom: 0;
  border-top: 0;
  &:nth-last-of-type(1) {
    border-bottom: 1px solid #eee !important;
  }
  .collect_title {
    background: rgba(255, 227, 114, 0.2);
  }
  .errTitle {
    background: rgba(108, 185, 245, 0.2);
    img {
      width: 32rem;
      height: 32rem;
      margin-top: 6rem;
    }
  }
  .title {
    //
    display: flex;
    justify-content: space-between;

    div {
      display: inline-block;
      height: 45rem;
      line-height: 45rem;
      padding: 2rem 0;
      font-size: 24rem;
    }

    .btn {
      // width: 164rem;
      background: #6cb9f5;
      padding: 2rem 43rem 1rem 23rem;
      color: #fff;
      border-radius: 9px 24px 24px 0px;
      padding: 2rem 43rem 1rem 43rem;
      margin-right: 33rem;
      cursor: pointer;
    }
    .btn_del {
      background: #ffe065;
      padding: 2rem 43rem 1rem 43rem;
      margin-left: -20rem;
      position: relative;
      color: #fff;
      border-radius: 9px 24px 24px 0px;
      margin-right: 33rem;
      cursor: pointer;
    }

    .time {
      font-size: 20rem;
      line-height: 45rem;
      padding-right: 13rem;
    }
  }
  .content {
    padding: 23rem;
    font-weight: 400;
    color: #333333;
    cursor: pointer;
    .topicTitle {
      font-size: 24rem;
      line-height: 44rem;
      min-height: 100rem;
      // padding-bottom: 10rem;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 5;
      -webkit-box-orient: vertical;
      ::v-deep img {
        vertical-align: middle;
      }
    }

    // :nth-child(2) {
    //   font-size: 26rem;
    //   line-height: 36rem;
    // }

    // :nth-child(2) {
    //   margin-top: 43rem;
    //   font-size: 26rem;
    //   line-height: 36rem;
    //   color: #666;
    // }

    .note {
      margin-top: 30rem;
      font-size: 24rem;
      line-height: 36rem;

      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      color: #666;
    }
  }
}
</style>