<template>
  <div class="subject_card_wrap">
    <div class="wrap1"
         style="min-height: 136rem;">
      <div class="wrap">
        <div :class="{'item':true,'active':item.subject_id == subjectId}"
             :style="{backgroundImage:item.subject_id == subjectId?'url('+require(`@/assets/subject/${item.subject_name}bg@2x.png`)+')':''}"
             v-for="item in list"
             @click="checkSubject(item.subject_id)"
             :key="item.subject_id">
          <img :src="require(`@/assets/subject/mk-${item.subject_name}@2x.png`)"
               alt="">
          <div class="text">
            <div>{{item.subject_name}}</div>
            <div>{{item.count}}</div>
          </div>
          <!-- <template v-if='item.subject_id!=13'>
            <img :src="require(`@/assets/leftcontroller/icon-${item.subject_name}-${item.subject_id == subjectId?'press':'normal'}@2x.png`)"
               alt="">
            <span>{{item.subject_name}}</span>
          </template> -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getStudentSubject } from '@/api/subject.js'
import { getWrongSubject, getCollectSubject } from '@/api/errorbasis.js'
import { mapMutations, mapState } from 'vuex'
export default {
  data () {
    return {
      subjectList: [],
      subjectId: '',
      type: ''//区别错题本还是收藏本
    }
  },
  props: {
    delYY: {
      type: Boolean,
      default: false
    }
  },
  mounted () {
    this.initData()
  },
  computed: {
    // ...mapState(['subjectList']),
    list () {
      return this.subjectList.filter(item => {
        // 技术不展示科目
        if (item.subject_id != 13) {
          // 如果是章节，删除语文英语
          if (this.delYY) {
            if (item.subject_id != 7 && item.subject_id != 11) {
              return item
            }
          } else {
            return item
          }

        }
      })
    }
  },
  methods: {
    //...mapMutations(['SET_SUBJECT']),
    back () {
      this.$router.go(-1)
    },
    async initData (type, subject_id) {
      this.type = type
      // if (!this.subjectList.length) {
      //   const { data } = await getStudentSubject()
      //   this.SET_SUBJECT(data.list)
      // }
      let dataList
      if (type == '1') {
        dataList = await getWrongSubject()
        // console.log(dataList)
        this.subjectList = dataList.data.list
        if (subject_id && this.subjectList.filter(item => item.subject_id == subject_id).length > 0) {
          this.subjectId = subject_id
        } else {
          this.subjectId = this.subjectList[0].subject_id
        }
        this.getData()
      }
      else if (type == '2') {
        dataList = await getCollectSubject()
        // console.log(dataList)
        this.subjectList = dataList.data.list
        if (subject_id && this.subjectList.filter(item => item.subject_id == subject_id).length > 0) {
          this.subjectId = subject_id
        } else {
          this.subjectId = this.subjectList[0].subject_id
        }
        this.getData()
      }
    },
    checkSubject (id) {
      if (this.subjectId == id) return
      this.subjectId = id
      this.getData()
    },
    getData () {
      this.$emit('initList', this.subjectId)
    }
  }
}
</script>

<style lang="scss" scoped>
.subject_card_wrap {
  width: 100%;
  overflow: auto;
  box-sizing: border-box;
  min-height: 136rem;
  // margin: 0 15rem;
}
.wrap {
  display: flex;
  width: fit-content;
  min-height: 136rem;

  .item {
    width: 210rem;
    height: 90rem;
    display: flex;
    background: #ffffff;
    background-size: 100% 100%;
    box-shadow: 0px 4px 8px 1px rgba(0, 0, 0, 0.16);
    border-radius: 9px 9px 9px 9px;
    margin: 20rem 10rem;
    padding: 10rem 18rem 18rem 8rem;
    box-sizing: border-box;
    cursor: pointer;
  }
  .text {
    flex: 1;
    text-align: right;
    :nth-child(1) {
      font-size: 30rem;
      color: #333333;
      margin-bottom: 3rem;
    }
    :nth-child(2) {
      font-size: 22rem;
      color: #999;
    }
  }
  img {
    // width: 58rem;
    height: 58rem;
    margin-top: 6rem;
  }
}
.active {
  color: #2196f3 !important;
}
/* 整个滚动条 */
::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}
/* 滚动条上的按钮 (上下箭头). */
::-webkit-scrollbar-button {
  display: none;
}
/* 滚动条上的滚动滑块. */
::-webkit-scrollbar-thumb {
  background-color: rgba(202, 202, 210, 0.8);
  border-radius: 50px;
  cursor: pointer;
}
/* 滚动条没有滑块的轨道部分 */
::-webkit-scrollbar-track-piece {
  border-radius: 20px;
}
/* 当同时有垂直滚动条和水平滚动条时交汇的部分. */
::-webkit-scrollbar-corner {
  background: transparent;
}
::v-deep .el-input--medium {
  font-size: 26rem;
}
</style>